//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: 'CollectionsMain',
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      collections: [],
    }
  },
  async mounted() {
    let params = this.getUrlParams
    if (params.hs == null){
      this.$toast.error("Store name cannot be null or empty!");
      return
    }

    await this.$axios
      .$get('/ProductCategories/category/end-with/'+ params.hs)
      .then((productCatalogs) => {
        this.collections = productCatalogs.value

        this.collections.forEach(c => {
            if (c.name.includes("("))
              c.name = c.name.split("(")[0]
        })
      })
  },
  computed:{
    ...mapGetters("frontend/urlParamStore",["getUrlParams"])
  }
}
